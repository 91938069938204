import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Hero, HeroText } from "../components/Hero"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Page introuvable" />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Page Introuvable"/>
    </Hero>

  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/error/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
